@use '/src/common/Styles/colors';
@use '/src/common/Styles/font';


div.sponsor_search {
  color: colors.$colors-PrimaryDefault;

  :after {
    border-bottom: 2px solid colors.$colors-PrimaryDefault
  }
}
.stack_control {
  display: flex;
  align-items: end;
  justify-content: space-between; 
}
.sort_button {
  transform: rotate(90deg);
  margin-left: 10px; 
}

.sort_title {
  color: colors.$colors-PrimaryDefault;
  margin-right: 10px;
}

.progress_bar{
  display: 'flex';
  justifyContent: 'center';
  alignItems: 'center';
  height: '100vh';
}
