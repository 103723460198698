@use '/src/common/Styles/colors';
@use '/src/common/Styles/font';

.cardHeader {  
  font-size: font.$fontsize-BodyLarge;
  font-family: font.$font-family_body;
  line-height: 30px;
  color: colors.$colors-PrimaryDarkest;
  display: flex;
  align-items: center;
}
.divider {
  margin: 10px 0 0 0;
}
.headerLabel{
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.chevron {
  font-size: xx-large;
  color: colors.$colors-GreyscaleMediumDark;
}

.data_grid_container {
  border: none;
  justify-content: center;
  align-items: center;
  width: 100%;
}

svg {
  &.flagged {
    color: colors.$colors-SecondaryDefault;
    position: absolute;
    left: -50%;
  }
}

.data_grid {
  width: 100%;
  border: none;
}

.data_grid .MuiDataGrid-root {
  border: none;
}

.data_grid .MuiDataGrid-cell {
  border: none;
}

.data_grid .MuiDataGrid-columnHeaderTitle {
  font-size: 16px;
}

.data_grid .MuiDataGrid-columnSeparator {
  visibility: hidden;
}

.data_grid .MuiDataGrid-row {
  min-height: 70px;
  max-height: 70px;
  height: 70px;
}
cost
.dropdown {
  width: 100%;
}

.scrollable_dropdown {
  overflow-y: auto;
  max-height: 200;
  width: 250;
  color: aqua;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.editIcon {
  margin-left: auto;
  margin-bottom: 8px;
}

.data_grid_container {
  position: relative;
  padding-bottom: 40px; /* Adjust this value as needed to provide space for the footer */
}

.custom_chip_box {
  display: flex;
  flex-wrap: wrap;
}

.dropdown {
  min-width: 150px;
}

.scrollable_dropdown {
  max-height: 200px;
  overflow-y: auto;
}
